.coverLogo {
  width: 300px;
}
.carouselSubtext {
  color: #F0E673;
}

.classes_img img {
  max-width: 100%;
}

.titleSvg {
  max-height: 100px;
  margin-right: 20px;
}

.cover {
  background-repeat: repeat;
  position: relative;
  background-color: #E62767;
  overflow: hidden;
  width: 100%;
  min-height: 50vh;
}

.bgPattern {
  background-repeat: repeat;
}

.waveContainer {
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.classCard {
  border-radius: 30px;
}

.childFont {
  font-family: "Gotham Rounded", sans-serif;
}

.adultFont {
  font-family: "Open Sans", sans-serif;
}

.facilitiesCard {
  border-radius: 30px;
  padding: 10px;
  width: 300px;
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

.facilitiesCardImg {
  text-align: center;
}

.facilitiesCardImg > img {
  border-radius: 50%;
  width: 80%;
  box-shadow: 5px 5px 5px #68686820;
}

.facilitiesCardTitle {
  text-align: center;
}

.facilitiesCardTitle > h2 {
  font-weight: 800;
  font-size: 2rem;
  word-break: break-word;
}

.testimonySliderItem > img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.testimonyContent {
  position: relative;
}

.testimonyContent > img {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100px;
  opacity: 0.1;
  margin-top: -50px;
}

.testimonyContent > p {
  font-size: 1.5rem;
}

.testimonySliderIndicators {
  margin-bottom: -20px !important;
}

.testimonySliderIndicators > li {
  background-color: #000 !important;
}

.footer {
  background-color: #f3f2f2;
}

.footerImgRow {
  margin-top: -280px;
}

.footerImg {
  max-width: 400px;
  border-radius: 10px;
  margin-bottom: 20px;
  z-index: 1;
}

.footerCopyrightContainer {
  border-top: 2px solid #49494930;
  padding-top: 10px;
}

.footerImg:hover {
  transform: translateY(-10px);
}

.footerRights {
  font-size: 0.8rem;
}

.footerContent img {
  max-width: 250px;
}

@media (max-width: 1200px) {
  .footerImgRow {
    margin-top: -200px;
  }
}

@media (max-width: 800px) {
  .testimonySliderItem {
    flex-direction: column;
  }
  .testimonySliderItem > img {
    height: 200px;
    width: 200px;
    margin-bottom: 10px;
  }
  .testimonyContent > p {
    font-size: 1rem;
  }
  .footerImgRow {
    margin-top: 0px;
  }
  .footerCopyrightContainer {
    flex-direction: column;
    text-align: center;
  }
  .footerContent {
    flex-direction: column;
    padding: 10px;
  }
  .footerContainer {
    max-width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 450px) {
  .facilitiesCard {
    width: 300px;
  }
  .facilitiesCardTitle > h2 {
    font-size: 1.5rem;
  }
  .footerImg {
    max-width: 300px;
  }
  .footerContent img {
    max-width: none;
    max-height: 200px;
  }
}

@media (max-width: 400px) {
  .facilitiesCard {
    width: 250px;
  }
  .coverLogo {
    max-width: 250px;
  }
  .footerImg {
    max-width: 250px;
  }
}