.metaContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 800px) {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
}

.metaContent {
  text-align: left;
  max-width: 500px;
  margin-right: 10px;
  @media (max-width: 800px) {
    order: 2;
    max-width: none;
  }
}

.metaImg {
  max-width: 30vw;
  margin-left: 10px;
  @media (max-width: 800px) {
    order: 1;
    margin-bottom: 20px;
    max-width: none;
    max-height: 300px;
    max-width: 100%;
  }
}

.infoBadge {
  background-color: #1CA3E6;
  color: #ffffff;
  font-weight: 500;
  border-radius: 20px;
  padding: 5px;
  font-size: 1rem;
  margin-bottom: 10px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.programsCardContainer {
  border-radius: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  box-shadow: 2px 2px 10px 0px #28282830;

  img {
    border-radius: 50%;
    max-width: 150px;
    margin-right: 10px;
  }

  .programsCardContent {
    span {
      font-weight: 600;
      font-size: 2rem;
    }
    p {
      font-weight: 500;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    img {
      margin-right: 0;
      margin-bottom: 10px;
      max-width: 100px;
    }

    .programsCardContent {
      span {
        font-weight: 600;
        font-size: 1.2rem;
      }
      p {
        font-weight: 500;
        font-size: 0.8rem;
      }
    }
  }
}